import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/@apollo/client-react-streaming/dist/index.cc.cjs");
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/@liquorice/next-apollo/src/ApolloWrapper/ApolloWrapperInternal.js");
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2FPageHeader%2FPageHeader.css.ts.vanilla.css%22%2C%22source%22%3A%22Ll8xN2YxN3VmMCB7CiAgb3ZlcmZsb3c6IGhpZGRlbjsKfQouXzE3ZjE3dWYxIHsKICBiYWNrZ3JvdW5kLWNvbG9yOiAjQTBEMEU1Owp9Ci5fMTdmMTd1ZjIgewogIHdpZHRoOiAxMDAlOwogIGhlaWdodDogdmFyKC0tc213eDV1MWQpOwogIGJhY2tncm91bmQ6IGxpbmVhci1ncmFkaWVudCgwZGVnLCAjRkZGRkZGIDAlLCByZ2JhKDI1NSwgMjU1LCAyNTUsIDApIDEwMCUpOwogIG9wYWNpdHk6IDAuMjsKfQouXzE3ZjE3dWY1IHsKICBwb3NpdGlvbjogcmVsYXRpdmU7CiAgZGlzcGxheTogaW5saW5lLWJsb2NrOwogIHdpZHRoOiAxMDAlOwogIGhlaWdodDogMTAwJTsKfQouXzE3ZjE3dWY2IHsKICBvdmVyZmxvdzogaGlkZGVuOwogIGhlaWdodDogMTAuOTM3NXJlbTsKfQouXzE3ZjE3dWY4IHsKICBtYXgtaGVpZ2h0OiAyLjVyZW07CiAgd2lkdGg6IGF1dG87Cn0KQG1lZGlhIHNjcmVlbiBhbmQgKG1pbi13aWR0aDogNzY4cHgpIHsKICAuXzE3ZjE3dWY2IHsKICAgIGhlaWdodDogMTUuMDYyNXJlbTsKICB9CiAgLl8xN2YxN3VmYSB7CiAgICB3aWR0aDogMTIwJTsKICB9Cn0KQG1lZGlhIHNjcmVlbiBhbmQgKG1pbi13aWR0aDogMTAyNHB4KSB7CiAgLl8xN2YxN3VmNiB7CiAgICBoZWlnaHQ6IDI4LjI1cmVtOwogIH0KfQ%3D%3D%22%7D");
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2FShim%2FShim.css.ts.vanilla.css%22%2C%22source%22%3A%22Ll8xb3EzbnU3MCB7CiAgcG9zaXRpb246IHJlbGF0aXZlOwp9Ci5fMW9xM251NzEgewogIGNsaXAtcGF0aDogY2lyY2xlKCk7Cn0KLl8xb3EzbnU3MiB7CiAgcG9zaXRpb246IGFic29sdXRlOwogIHRvcDogMDsKICBsZWZ0OiAwOwogIGJvdHRvbTogMDsKICByaWdodDogMDsKfQ%3D%3D%22%7D");
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/Blocks/Blocks.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/Image/Image.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/PageHeader/PageHeader.css.ts");
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/PageHeader/PageHeader.ShareButtons.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/Shim/Shim.css.ts");
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/layouts/ContextLayout/ContextLayout.tsx")